<template>
    <div>
        <!-- head title -->
        <div class="my-10">
      <span class="darkprimary--text font-33 font-700 d-block">{{
          $t("forms.verifyAccount")
          }}</span>
            <span class="pruple--text font-21 font-500 d-block">{{
                $t("forms.checkEmail")
                }}</span>
        </div>

        <!-- Verification code -->
        <v-form
                v-model="valid"
                ref="doctorValidationStep"
                @submit.prevent="handleNext"
        >
            <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.verificationCode")
            }}</span>
                <base-input
                        v-model="verifyCode"
                        class="mt-1"
                        type="number"
                        :placeholder="$t('forms.enterVerificationCode')"
                        :hideDetails="false"
                ></base-input>
            </div>

            <!-- btn -->
            <base-register-btn
                    class="mt-2"
                    :title="$t('forms.verify')"
                    :loading="loading"
                    type="submit"
            >
            </base-register-btn>
        </v-form>

        <div class="text-center mt-3" @click="timerHandler">
      <span
              class="darkprimary--text font-16 font-500"
              :class="resendAvailable ? 'cursor-pointer ' : 'no-drop opacity-5'"
      >{{ $t('forms.codeResend') }} <span v-if="remaining > 0"> {{ remaining }}</span></span
      >
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    props: {
        emailObj: {
            type: Object,
            default: () => {
            },
        },
    },
    data: () => ({
        loading: false,
        verifyCode: "",
    }),
    computed: {
        ...mapGetters(["remaining", "resendAvailable"]),
    },
    methods: {
        async handleNext() {
            let valid = this.$refs.doctorValidationStep.validate();
            if (!valid) {
                return;
            }
            this.loading = true;

            let formData = new FormData();

            formData.append("email", this.emailObj.doctor.email);
            formData.append("verification_code", this.verifyCode);
            let res = await this.$axios.post("doctor/verification/code", formData);
            this.loading = false;
            if (res.success || res.status == 200) {
                this.$emit("next", res.data.data);
            } else {
                this.$store.dispatch("showSnack", {
                    text: res.message,
                    color: "error",
                });
            }
        },
        timerHandler() {
            this.$store.dispatch("timerHandler", {
                email: this.emailObj.doctor.email,
                url: "doctor/add",
            });
        },
    },
    created() {
        if (!localStorage.endTime) {
            this.timerHandler();
        } else {
            this.remaining = +localStorage.endTime;
            this.timerHandler();
        }
    },
};
</script>

<style></style>
