<template>
  <div>
    <!-- head title -->
    <div class="my-10">
      <span class="darkprimary--text font-33 font-700">
        {{ $t("forms.createPassword") }}
      </span>
    </div>
    <v-form
      v-model="valid"
      ref="doctorPasswordStep"
      @submit.prevent="handleNext"
    >
      <!-- Password -->
      <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{$t('forms.password')}}</span>
        <base-password
          v-model="obj.password"
          class="mt-1"
          :placeholder="$t('forms.enterPassword')"
          :hideDetails="false"
        ></base-password>
      </div>

      <!-- btn -->
      <base-register-btn
        :title="$t('forms.create')"
        :loading="loading"
        type="submit"
      >
      </base-register-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  props: {
    emailObj: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    obj: {},
    loading: false,
    valid: false
  }),
  methods: {
    async handleNext() {
      let valid = this.$refs.doctorPasswordStep.validate();
      if (!valid) {
        return;
      }
      this.loading = true;

      let formData = new FormData();
      formData.append("password", this.obj.password);
      formData.append("password_confirmation", this.obj.password);
      formData.append("email", this.emailObj.doctor.email);
      let res = await this.$axios.post("doctor/password/set", formData);

      this.loading = false;
      if (res.data) {
        this.$emit("next", res.data);
      } else {
        this.$store.dispatch("showSnack", {
          text: res.errors.password[0],
          color: "error",
        });
      }
    },
  },
};
</script>

<style></style>
