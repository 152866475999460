<template>
  <div class="signup">
    <transition name="fade" mode="out-in">
      <!-- email step -->
      <email-step v-if="step == 1" @next="handleStepEmail"></email-step>

      <!-- verify step -->
      <verify-step
        :key="step"
        v-if="step == 2"
        @next="handleStepVerify"
        :emailObj="obj"
      ></verify-step>

      <!-- password step -->
      <password-step
        :key="step"
        v-if="step == 3"
        @next="handleStepPassword"
        :emailObj="obj"
      ></password-step>
      <!-- information step -->
      <info-step  v-if="step == 4" @next="handleStepInfo"  :emailObj="obj"></info-step>

      <!-- general step -->
      <general-step @prevStep="step = 4" v-if="step == 5" @click="step = 1" :emailObj="obj"></general-step>
    </transition>

    <div class="text-center mt-10">
      <p class="greytext--text font-18">
        {{ $t("heroPage.alreadyAccount") }}
        <router-link to="/register/login"
          ><span class="font-18 darkprimary--text cursor-pointer font-700"
            >{{ $t("heroPage.loginNow")}}</span
          ></router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import EmailStep from "./steps/Email";
import PasswordStep from "./steps/Password";
import VerifyStep from "./steps/Verify";
import InfoStep from "./steps/Info";
import GeneralStep from "./steps/General";
export default {
  components: {
    EmailStep,
    PasswordStep,
    VerifyStep,
    InfoStep,
    GeneralStep,
  },
  data: () => ({
    step: 1,
    obj: {},
  }),
  methods: {
    handleStepEmail(obj) {
      this.obj = obj;
      this.step = 2;
    },
    handleStepVerify() {
      this.step = 3;
    },
    handleStepPassword() {
      this.step = 4;
    },
    handleStepInfo(val) {
      this.obj.first_name = val.first_name
      this.obj.last_name = val.last_name
      this.step = 5;
    },
  },
};
</script>

<style></style>
