<template>
    <div>
        <!-- head title -->
        <div class="my-10">
      <span class="greytext--text font-21 d-block">{{
          $t("forms.welcomeDoctor")
          }}</span>
            <span class="darkprimary--text font-33 font-700 d-block mt-5">{{
                $t("forms.fillForm")
                }}</span>
        </div>
        <v-form v-model="valid" ref="doctorInfoStep" @submit.prevent="handleNext">
            <!-- First name -->
            <div class="mt-4">
        <span class="darktext--text font-18 font-600">{{
            $t("forms.firstName")
            }}</span>
                <base-input
                        v-model="obj.first_name"
                        placeholder="Ex: Ahmad"
                        :rules="nameRules"
                        :hideDetails="false"
                ></base-input>
            </div>
            <!-- Last name -->
            <div>
        <span class="darktext--text font-18 font-600">{{
            $t("forms.lastName")
            }}</span>
                <base-input
                        v-model="obj.last_name"
                        placeholder="Ex: Maher"
                        :rules="nameRules"
                        :hideDetails="false"
                ></base-input>
            </div>

            <!-- btn -->
            <base-register-btn class="mt-3" :title="$t('forms.next')" type="submit">
            </base-register-btn>
        </v-form>
    </div>
</template>

<script>
export default {
    props: {
        emailObj: {
            type: Object,
            default: () => {
            },
        },
    },
    data: (vm) => ({
        obj: {},
        nameRules: [
            (v) => !!v || vm.$t('rules.required'),
            (v) => (v && v.length > 1) || vm.$t('rules.require3Char'),
        ],
    }),
    methods: {
        handleNext() {
            let valid = this.$refs.doctorInfoStep.validate();
            if (!valid) {
                return;
            }
            this.$emit("next", this.obj);
        },
    },
    created() {
        if (this.emailObj.first_name) {
            this.obj = {...this.emailObj}
        }
    }
};
</script>

<style></style>
